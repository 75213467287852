import React from 'react';
import leijonicLogo from './leijonic_lion.svg'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <img src={leijonicLogo} className="App-logo" alt="Leijonic.com" />
      <img src={leijonicLogo} className="App-logo-bg" alt="Leijonic.com" />
      <label>
        Leijonic.com
      </label>
        <p>
          <code>💬 Stay tuned and check back later.</code>
        </p>
      </header>
    </div>
  );
}

export default App;
